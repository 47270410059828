import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PageHeader = ({
  image,
  header,
  detail,
  buttons = [],
  bannerBg,
  children,
}) => {
  return (
    <div className="page-header">
      <span
        className="page-header-band"
        style={{ backgroundColor: bannerBg }}
      ></span>
      <div className="container page-header-inner">
        {children ? (
          children
        ) : (
          <>
            <GatsbyImage
              image={getImage(image.localFile)}
              alt={header}
              className="page-header-image"
            />

            <div className="page-header-text">
              <h2 className="page-header-title">{header}</h2>
              <p className="page-header-detail">{detail}</p>

              {buttons.length > 0 && (
                <div className="page-header-buttons">
                  {buttons.map(button => (
                    <a
                      href={button.link}
                      className="btn"
                      style={{
                        color: button.textColor,
                        backgroundColor: button.backgroundColor,
                      }}
                      onMouseEnter={e => {
                        e.target.style.color = button.textHoverColor
                        e.target.style.backgroundColor =
                          button.backgroundHoverColor
                      }}
                      onMouseLeave={e => {
                        e.target.style.color = button.textColor
                        e.target.style.backgroundColor = button.backgroundColor
                      }}
                    >
                      {button.text}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PageHeader
