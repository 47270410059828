import React, { useState } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

export default function Faq({ list = [], bgColor }) {
  const [activeItemId, setActiveItemId] = useState(null)

  return (
    <Accordion allowZeroExpanded onChange={([uuid]) => setActiveItemId(uuid)}>
      {list.map(item => (
        <AccordionItem key={item.id} uuid={item.id}>
          <AccordionItemHeading>
            <AccordionItemButton
              style={{
                backgroundColor:
                  activeItemId === item.id ? bgColor : `${bgColor}CC`,
              }}
              onMouseEnter={e => {
                e.target.style.backgroundColor = `${bgColor}`
              }}
              onMouseLeave={e => {
                if (activeItemId !== item.id) {
                  e.target.style.backgroundColor = `${bgColor}CC`
                }
              }}
            >
              {item.question}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{item.answer}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
