import React from "react"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"

import MainLayout from "../layouts/MainLayout"
import Seo from "../components/Seo"
import PageHeader from "../components/PageHeader"
import SidebarMenu from "../components/SidebarMenu"
import Faq from "../components/Faq"
import "../i18n"
import useChangeLanguage from "../hooks/useChangeLanguage"

export default function FaqPage({ data, pageContext }) {
  const { t } = useTranslation()
  const { locale, menu } = pageContext
  useChangeLanguage({ locale })

  return (
    <MainLayout alternativeSlug={locale === 'tr-TR' ? '/faq' : '/sikca-sorulan-sorular'} locale={locale}>
      <Seo
        title={`${data.strapiFaq.seo.title}`}
        description={`${data.strapiFaq.seo.description}`}
      />

      <PageHeader
        image={data.strapiFaq.header.image}
        header={data.strapiFaq.header.title}
        detail={data.strapiFaq.header.description}
        buttons={data.strapiFaq.header.buttons}
        bannerBg={data.strapiFaq.type.backgroundColor}
      />

      <div className="container page-content">
        <aside className="page-content-menu">
          {menu?.urls && <SidebarMenu menu={menu.urls} />}
        </aside>

        <div className="page-content-text">
          <Faq bgColor={data.strapiFaq.type.backgroundColor} list={data.strapiFaq.items} />
        </div>
      </div>
    </MainLayout>
  )
}

export const faqPageQuery = graphql`
  query ($id: String!) {
    strapiFaq(id: { eq: $id }) {
      header {
        title
        description
        buttons {
          textColor
          textHoverColor
          backgroundColor
          backgroundHoverColor
          link
          text
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      type {
        backgroundColor
      }
      items {
        id
        answer
        question
      }
      seo {
        title
        description
        keywords
      }
      locale
      id
    }
  }
`
